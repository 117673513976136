<template>
    <div class="billCheckDetailReport" data-v-aaaab>
        <div class="info-box">
            <span class="tag-red" v-if="data.Is_RedBill">红冲</span>
            <div class="panel-box top-info-box">
                <el-row>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6" >
                        <span class="lable-text">结账单号：{{billInfo.Eat_CheckNo}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6">
                        <span class="lable-text">结账人：{{billInfo.Eat_Czy}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">结账时间：{{ formatter(billInfo.Eat_CheckTime) }}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <el-tooltip  effect="dark" :content="billInfo.Eat_DeskName" placement="bottom">
                            <span class="lable-text">台号：{{billInfo.Eat_DeskName}}</span>
                        </el-tooltip>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">订单渠道：{{billInfo.ChannelName}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">消费类型：{{billInfo.Consume_TypeName}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">人数：{{billInfo.Eat_ManNum}}人</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">席数：{{billInfo.Eat_DeskNum}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">餐厅：{{billInfo.Eat_RoomName}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">会员卡号：{{billInfo.Member_CardNo}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                        <span class="lable-text">会员姓名：{{billInfo.Cstm_Name}}</span>
                    </el-col>
                </el-row>
            </div>
            <div class="panel-box table-box grey-table">
                <el-table ref="tableEl" :data="tableBillConsumes"  style="width: 100%;height:100%" 
                    :span-method="spanMethod"
                     v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column fixed="left" prop="" label="标记" width="85" align="left">
                        <template #default="scope">
                            <span v-if="scope.row.isTotal">台号：{{scope.row.Eat_DeskName}}</span>
                            <template v-else>
                                <span class="tag tag-give" v-if="scope.row.IS_Gift">赠</span>
                                <span class="tag tag-tui" v-if="scope.row.IS_Refund">退</span>
                                <span class="tag tag-tao" v-if="scope.row.IS_Member">套</span>
                                <span class="tag tag-discount" v-if="scope.row.IS_ZK">折</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" prop="EAT_XFCode" label="菜品编码" width="110" show-overflow-tooltip align="left"></el-table-column>
                    <el-table-column fixed="left" prop="EAT_XFName" label="菜品名名称" width="140" show-overflow-tooltip align="left"></el-table-column>
                    <el-table-column fixed="left" prop="Eat_XFSize" label="单位" width="60" show-overflow-tooltip> </el-table-column>
                    <el-table-column label="数量">
                        <el-table-column prop="Eat_Number" label="点单数量" min-width="70"></el-table-column>
                        <el-table-column prop="Refund_Number" label="退菜数量" min-width="70"></el-table-column>
                        <el-table-column prop="Gift_Number" label="赠菜数量" min-width="70"></el-table-column>
                    </el-table-column>
                    <el-table-column label="单价">
                        <el-table-column prop="Eat_SalePrice" label="原价" min-width="50" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Eat_CheckPrice" label="售价" min-width="50" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Eat_tcAVEPrice" label="套餐均价" min-width="70" :formatter="priceFormat"></el-table-column>
                    </el-table-column>
                    <el-table-column label="金额">
                        <el-table-column prop="Eat_SaleMoney" label="折前金额" min-width="70"></el-table-column>
                        <el-table-column prop="Eat_CheckMoney" label="结算金额" min-width="70"></el-table-column>
                    </el-table-column>
                    <el-table-column label="时间">
                        <el-table-column prop="Eat_Time" label="点单" min-width="65" :formatter="shortDateFormat"></el-table-column>
                        <el-table-column prop="Eat_CrossTime" label="出品" min-width="65" :formatter="shortDateFormat"></el-table-column>
                        <el-table-column prop="Eat_RefundTime" label="退菜" min-width="65" :formatter="shortDateFormat"></el-table-column>
                        <el-table-column prop="Eat_GiftTime" label="赠送" min-width="65" :formatter="shortDateFormat"></el-table-column>
                    </el-table-column>
                    <el-table-column label="人员">
                        <el-table-column prop="Eat_Czy" label="点菜人" min-width="70" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="Eat_CookerName" label="厨师" min-width="70" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="Eat_RefundCzy" label="退菜人" min-width="78" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="Eat_GiftCzy" label="赠送人" min-width="78" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="Eat_ChangeCzy" label="改价人" min-width="78" show-overflow-tooltip></el-table-column>
                    </el-table-column>
                    <el-table-column label="操作原因">
                        <el-table-column prop="Refund_Reason" label="退菜原因" min-width="100">
                            <template #default="scope">
                                <el-tooltip effect="dark" :content="scope.row.Refund_Reason" placement="top">
                                    <div>{{ scope.row.Refund_Reason }}</div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Gift_Reason" label="赠送原因" min-width="100">
                            <template #default="scope">
                                <el-tooltip effect="dark" :content="scope.row.Gift_Reason" placement="top">
                                    <div>{{ scope.row.Gift_Reason }}</div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Change_Reason" label="改价原因" min-width="100">
                            <template #default="scope">
                                <el-tooltip effect="dark" :content="scope.row.Change_Reason" placement="top">
                                    <div>{{ scope.row.Change_Reason }}</div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="Eat_MasName" label="要求" width="110">
                        <template #default="scope">
                            <el-tooltip effect="dark" :content="scope.row.Eat_MasName" placement="top">
                                <div>{{ scope.row.Eat_MasName }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <!---->
                    <el-table-column prop="Eat_Remark" label="备注" width="110">
                        <template #default="scope">
                            <el-tooltip effect="dark" :content="scope.row.Eat_Remark" placement="top">
                                <div>{{scope.row.Eat_Remark}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="panel-box total-info">
                <el-row >
                    <el-col :xs="12" :sm="12">
                        <span class="lable-text">尾数调整：{{billInfo.Mantissa_Money}}</span>
                        <span class="lable-text">服务费：{{billInfo.Service_Money}}</span>
                    </el-col>
                    <el-col :xs="12" :sm="12" class="text-right">
                        <span class="lable-text">小计：折前金额 {{billInfo.Eat_SaleMoney}}</span>
                        <span class="lable-text">结算金额 <span class="money"> {{billInfo.Eat_CheckMoney}}</span></span>
                    </el-col>
                </el-row>
            </div>
        </div>
        <tabs class="bottom-record-box">
            <div class="nav-box">
                <tab-nav class="nav-li">付款明细</tab-nav>
                <tab-nav class="nav-li">结账恢复记录</tab-nav>
                <tab-nav class="nav-li">修改付款方式记录</tab-nav>
                <tab-nav class="nav-li">打印单据记录</tab-nav>
            </div>
            <tab-pane v-slot="{ isShow }">
                <div class="table-box grey-table"  v-table-el-height="'tableEl'" v-if="isShow">
                    <el-table ref="tableEl" border :data="tableList"  style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="Payment_Name" label="付款方式" min-width="150"></el-table-column>
                        <el-table-column prop="Payment_Money" label="金额" min-width="140"></el-table-column> 
                        <el-table-column prop="Eat_Czy" label="收款人" min-width="220"></el-table-column> 
                        <el-table-column prop="Eat_PaymentTime" label="收款时间" min-width="240">
                            <template #default="scope">
                                <div>{{ new Date(scope.row.Eat_PaymentTime).Format("yyyy-MM-dd hh:mm:ss") }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </tab-pane>
            <tab-pane v-slot="{ isShow }">
                <div class="table-box grey-table"  v-table-el-height="'tableEl'" v-if="isShow">
                    <el-table ref="tableEl" border :data="ReverseCheckOutLogsList"  style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="Operator_Name" label="操作人" min-width="150"></el-table-column>
                        <el-table-column prop="Operate_Time" label="操作时间" min-width="240">
                            <template #default="scope">
                                <div>{{ new Date(scope.row.Operate_Time).Format("yyyy-MM-dd hh:mm:ss") }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </tab-pane>
            <tab-pane v-slot="{ isShow }">
                <div class="table-box grey-table"  v-table-el-height="'tableEl'" v-if="isShow">
                    <el-table ref="tableEl" border :data="ModifyPaymentLogsList"  style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="OldPayName" label="原支付方式名称" min-width="150"></el-table-column>
                        <el-table-column prop="NewPayName" label="修改后支付方式名称" min-width="150"></el-table-column>
                        <el-table-column prop="Operator_Name" label="操作人" min-width="150"></el-table-column>
                        <el-table-column prop="Operate_Time" label="操作时间" min-width="240">
                            <template #default="scope">
                                <div>{{ new Date(scope.row.Operate_Time).Format("yyyy-MM-dd hh:mm:ss") }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </tab-pane>
            <tab-pane v-slot="{ isShow }">
                <div class="table-box grey-table"  v-table-el-height="'tableEl'" v-if="isShow">
                    <el-table ref="tableEl" border :data="PrintOrderLogsList"  style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                        <el-table-column prop="Operator_Name" label="操作人" min-width="150"></el-table-column>
                        <el-table-column prop="Operator_Kind" label="操作类型" min-width="150"></el-table-column>
                        <el-table-column prop="Operate_Time" label="操作时间" min-width="240">
                            <template #default="scope">
                                <div>{{ new Date(scope.row.Operate_Time).Format("yyyy-MM-dd hh:mm:ss") }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </tab-pane>
        </tabs>
    </div>
</template>

<script>
/**单据稽核报表 订单详情 */
export default {
    name:'billDetail',
    props:{
        data:{}
    },
    data(){
        return {
            /**账单数据 */
            billInfo:{},
            tableList:[], /**支付明细信息 */
            ReverseCheckOutLogsList:[], /**结账恢复记录 */
            ModifyPaymentLogsList:[], /**修改支付方式记录 */
            PrintOrderLogsList:[],/**打印单据记录 */
            userInfo:'',
            
        }
    },
    computed:{
        /**订单明细信息 */
        tableBillConsumes(){
            let list=[];
            let groupKey={}
            this.billInfo?.BillConsumes?.forEach(it=>{
                if(!groupKey[it.Eat_DeskName]){
                    groupKey[it.Eat_DeskName]=it;
                    list.push({
                        isTotal:true,
                        Eat_DeskName:it.Eat_DeskName
                    });
                }
                list.push(it);
            })
            return list;
        }
    },
    mounted(){
        this.$emit("navIndex",1);
        this.$nextTick(()=>{
            this.userInfo = this.$auth.getUserInfo();
            this.loadDetailData();
        });
    },
    methods:{
        /**加载明细数据 @param id 订单id*/
        loadDetailData(){
            let id=this.data.checkNo;
            let Rpt_Date=this.data.Rpt_Date
            if(!id || id==''){
                this.$alert('账单id错误', '提示', {
                    confirmButtonText: '确定',
                    callback: (name) => {
                        if(name=='confirm'){
                            this.$emit("close");
                        }
                    }
                });
                return ;
            }
            let userInfo= this.$auth.getUserInfo();
            if(userInfo && Rpt_Date && new Date(userInfo.Rpt_Date).toString('yyyy-MM-dd')==new Date(Rpt_Date).toString('yyyy-MM-dd')){//营业日期 当天
                this.isOldBill=false;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_CheckNo:id,
                Rpt_Date:Rpt_Date,
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }
            this.$httpAES.post("Bestech.CloudPos.BillCheckReportDetail",param).then((d)=>{
                loading.close();
                console.log('BillCheckReportDetail:',d)
                if(d.ResponseHeader.ResultCode==0){
                    let info=d.ResponseBody||{};
                    this.timestamps=d.ResponseBody.Timestamps;
                    if(info.Eat_State==1){
                        this.$emit("close");                    
                    }else{
                        this.initOrderInfo(info);
                    }
                }else{
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: (name) => {
                            if(name=='confirm'){
                                this.$emit("close");
                            }
                        }
                    });
                }
            }).catch((e)=>{
                loading.close();
                this.$alert('加载账单数据失败：'+e, '提示', {
                    confirmButtonText: '确定',
                    callback: (name) => {
                        if(name=='confirm'){
                            this.$emit("close");
                        }
                    }
                });
                console.log('加载账单数据失败：'+e);
            })
        },
        initOrderInfo(info){
            info.Member_Name=info.Cstm_Name;//会员名称
            this.billInfo=info;
            this.tableList = info.PaymentDetailList
            this.ReverseCheckOutLogsList = info.ReverseCheckOutLogs
            this.ModifyPaymentLogsList = info.ModifyPaymentLogs
            this.PrintOrderLogsList = info.PrintOrderLogs
            let money=0;
            (info.Order_PaymentDetailList||[]).forEach(item => {
                money+=item.Payment_Money;
            });
            this.paymentMoney=parseFloat(money.toFixed(4));
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        formatter(cellValue,format) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format(format||'yyyy-MM-dd hh:mm');
        },
        /*时间格式 时分*/
        shortDateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('hh:mm');
        },
        /**合并行列 */
        spanMethod({ row, column, rowIndex, columnIndex }){
            if(row.isTotal){
                if(columnIndex==0){
                    return [1,26];
                }else {
                    return [0,0];
                }
            }
        },
    }
}
</script>

<style lang="scss">
    @import "./billCheckDetailReport.scss"
</style>